<script setup>
    // import LayoutDefault from "../components/layout/LayoutDefault.vue";
    // import ModuleContainer from "../components/containers/ModuleContainer";
    // import GiftCardDetails from "../components/elements/GiftCardDetails.vue";
    // import Hero2 from "../components/modules/Hero2.vue";
    // import TornPaperDivider from "../components/elements/TornPaperDivider.vue"
    window.location.href =  process.env.VUE_APP_REDIRECT_TO_BYPASS;
</script>
<!-- <template>
    <LayoutDefault :currentStudio="currentStudio" :queryParams="queryParams" :showHeader="showHeader"
                 :showFooter="showFooter">
      <ModuleContainer
          class="module-container-hero"
          containerFluid="true"
      >
        <Hero2
          imageSource="https://res.cloudinary.com/ilovekickboxing/image/upload/v1683570315/GIFT_Site/hero-banner-1.jpg"
          imageAlt="Hero Banner"
          text="Give the gift of fitness"
        />
      </ModuleContainer>
      <ModuleContainer
          padding="0 0 0 0"
          containerFluid="true"
          class="module-container-divider"
      >
          <TornPaperDivider
              type="WHITE_DIVIDER_TOP"
          />
      </ModuleContainer>
      <ModuleContainer backgroundColor="#F7F8F9" textColor="#0A072E">
        <GiftCardDetails />
      </ModuleContainer>
  </LayoutDefault>
</template> -->