import {post} from './http_handler';
import gmapsInit from "@/utils/gmaps";
import axios from "axios";
import {ref} from "vue";

const getLocationsById = async (url_slug) => {
    console.log('getLocationsById', url_slug)
    return post("/", {
        query:
            `{ location(slug: "` + url_slug + `"){
                        ID
                        Name
                        Line1
                        Line2
                        City
                        state{
                            Code
                            State
                            country{
                              CountryCode
                            }
                        }
                        ZipCode
                        County
                        phone
                        schools_details
                        staff_email
                        url_slug
                        lat
                        lng
                        mindbody_web_id
                        mindbody_site_id
                        mindbody_schedule_widget
                        rainmaker_school_id
                        rainmaker_api
                        facebook_link
                        instagram_link
                        twitter_link
                        youtube_link
                        tiktok_link
                        IsActive
                        products (active: true) {
                            ckt_products_id
                            category
                            short_description
                            button_description
                            description
                            internal_product_id
                            price
                            quantity
                            value
                            start_date
                            end_date
                            active_ind
                        }
                        mbServices {
                            Id
                            studio_id
                            ProductId
                            Price
                            OnlinePrice
                            TaxIncluded
                            TaxRate
                            ProgramId
                            Name
                            Count
                            SellOnline
                            Type
                            ExpirationType
                            ExpirationUnit
                            RevenueCategory
                            MembershipId
                            SellAtLocationIds
                            UseAtLocationIds
                            Priority
                            IsIntroOffer
                            IntroOfferType
                            IsThirdPartyDiscountPricing
                            Program
                            Discontinued
                          }
                          mbContracts {
                            Id
                            studio_id
                            Name
                            Description
                            AssignsMembershipId
                            AssignsMembershipName
                            SoldOnline
                            ContractItems {
                              Id
                              Name
                              Description
                              Type
                              Price
                              Quantity
                              OneTimeItem
                            }
                            IntroOffer
                            AutopaySchedule {
                              FrequencyType
                              FrequencyValue
                              FrequencyTimeUnit
                            }
                            NumberOfAutopays
                            AutopayTriggerType
                            ActionUponCompletionOfAutopays
                            ClientsChargedOn
                            ClientsChargedOnSpecificDate
                            DiscountAmount
                            DepositAmount
                            FirstAutopayFree
                            LastAutopayFree
                            ClientTerminateOnline
                            MembershipTypeRestrictions {
                              Id
                              Name
                            }
                            LocationPurchaseRestrictionIds
                            LocationPurchaseRestrictionNames
                            AgreementTerms
                            RequiresElectronicConfirmation
                            AutopayEnabled
                            FirstPaymentAmountSubtotal
                            FirstPaymentAmountTax
                            FirstPaymentAmountTotal
                            RecurringPaymentAmountSubtotal
                            RecurringPaymentAmountTax
                            RecurringPaymentAmountTotal
                            TotalContractAmountSubtotal
                            TotalContractAmountTax
                            TotalContractAmountTotal
                          }
                        googleReviews {
                            author
                            text
                            rating
                            author_img_url
                            status
                            time
                            location {
                                ID
                                Name
                                url_slug
                            }
                        }
                    }
                  }`
    })
        .then((response) => {
            console.log('getLocationsById response', response)
            if(response.data.data.location !== null) {
                return response.data.data.location;
            } 

        })
        .catch((err) => {
            console.log(err);
        });
}

const fetchStudioSchedule = (studioId = 0) => {
    console.log('schedule', studioId);

    return post('/', {
        query: `{
                mbschedules(studio_id: `+ studioId +`){
                    mb_schedule_id
                    studio_id
                    site_id
                    location_id
                    program_id
                    class_id
                    class_name
                    class_type
                    class_description
                    start_time
                    end_time
                    max_capacity
                    total_booked
                }
            }`
        })
        .then((response) => {
            console.log('fetchStudioSchedule', response)
            return response.data.data.mbschedules;
        })
        .catch((err) => {
            console.log('fetchStudioSchedule', err);
        });
}

const purchaseBooking = (schedule, mbid) => {
    console.log('purchaseBooking', schedule.mb_schedule_id, mbid);

    return post('/', {
        query: `mutation {
                  AddClassToMB(
                    input: {
                        mb_schedule_id: `+ schedule.mb_schedule_id +`,
                        mindbody_customer_id: "`+ mbid +`"
                    }
                  )
                }`
        })
        .then((response) => {
            console.log('purchaseBooking', response)
            return response.data.data.AddClassToMB;
        })
        .catch((err) => {
            console.log('purchaseBooking', err);
        });
}
const getLocationsByZipCode = async (zipcode, callback) => {
    const google = await gmapsInit();
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({'address': zipcode, 'region': 'us'}, function (results, status) {
        console.log('Results : ');
        console.log(results);
        if (status !== "OK" || !results[0]) {
            return 'Something went wrong please try again';
        }
        post("/", {
            query:
                `
                            query zipLocations($lat: String!, $lng: String!, $amount: Int = 25, $active: Boolean = true){
                                  zipLocations(lat: $lat, long: $lng, amount: $amount, active: $active) {
                                  ID
                                  Name
                                  lat
                                  lng
                                  Line1
                                  Line2
                                  City
                                  state {
                                      ID
                                      Code
                                      State
                                      country {
                                      ID
                                      CountryName
                                      CountryCode
                                      }
                                  }
                                  ZipCode
                                  phone
                                  url_slug
                                  }
                              }`,
            variables: {
                "lat": '"' + results[0].geometry.location.lat() + '"', "lng": '"' + results[0].geometry.location.lng() + '"'
            }
        })
            .then((response) => {
                callback(response.data.data.zipLocations, results[0].geometry.location.lat(), results[0].geometry.location.lng());
            })
            .catch((err) => {
                console.log(err);
            });
    });
}

const stripeCheckoutHandler = async (data, callback) => {
    console.log('stripeCheckoutHandler', data)

    post('/', {
        query: `mutation {
                giftCertificateCheckout(input: {
                studio: "`+ data.studio +`"
                token: "`+data.stripeToken+`"
                notes: "`+data.notes+`"
                customer: [
                    {name: "first_name", value: "`+data.customer_first_name+`"}
                    {name: "last_name", value: "`+data.customer_last_name+`"}
                    {name: "email", value: "`+data.customer_email+`"}
                    {name: "hear_about", value: "`+data.hear_about+`"}
                    {name: "phone", value: "`+data.customer_phone+`"}
                    {name: "customer_ip", value: "`+data.customer_ip+`"}
                    {name: "referrer", value: "`+data.referrer+`"}
                    {name: "device", value: "`+data.device+`"}
                    {name: "utm_agency", value:"`+data.utm_agency+`"}
                    {name: "utm_source", value:"`+data.utm_source+`"}
                    {name: "utm_offer", value:"`+data.utm_offer+`"}
                    ]
                recipient:[
                    {name: "first_name", value: "`+data.recipient_first_name+`"}
                    {name: "last_name", value: "`+data.recipient_last_name+`"}
                    {name: "email", value: "`+data.recipient_email+`"}
                    {name: "phone", value: "`+data.recipient_phone+`"}
                    {name: "gift_certificate_print", value: "Y"}
                ]
                cart: [
                    {
                    product_id: `+data.cart.product_id+`,
                    product:"`+data.cart.product+`",
                    price:`+data.cart.price*100+`,
                    qty:1,
                    type:"customer"
                    }
                    ]
                })
                {
            ckt_gift_certificate_id
            studio_id
            ckt_invoice_id
            ckt_invoice_line_item_id
            ckt_customer_id
            invoice_date
            first_name
            last_name
            mobile_phone
            email
            gift_certificate_id
            gift_certificate_print
            redeem_gift_certificate
            last_processed_by
            created_at
            location {
                ID
                Name
                url_slug
            }
            customer {
              ckt_customer_id
              first_name
              last_name
              email
              mobile_phone
              hear_about
            }
            lineItem {
                product {
                    ckt_products_id
                    short_description
                }
            }
            invoice {
              ckt_invoice_id
              studio_id
              ckt_customer_id
              ckt_coupon_id
              invoice_status
              refund_status
              product_amt
              product_cnt
              coupon_amt
              refund_amt
              invoice_amt
              notes
              payment_type
              transaction_id
              approval_code
              invoice_date
              invoice_timestamp
              refund_timestamp
              order_id_old
              checkout_url
              utm_agency
              utm_source
              utm_offer
              last_processed_by
              created_at
            }
        }
                }`
    })
        .then((response) => {
            callback(response.data)
        })
        .catch((err) => {
            console.log(err);
        });
}


const paypalCheckoutHandler = async (data, callback) => {
    console.log('paypalCheckoutHandler', data)
    post('/', {
        query: `mutation {
                giftCertificatePaypalCheckout(input: {
                studio: "`+ data.studio +`"
                token: ""
                notes: "`+data.notes+`"
                customer: [
                    {name: "first_name", value: "`+data.customer_first_name+`"}
                    {name: "last_name", value: "`+data.customer_last_name+`"}
                    {name: "email", value: "`+data.customer_email+`"}
                    {name: "hear_about", value: "`+data.hear_about+`"}
                    {name: "phone", value: "`+data.customer_phone+`"}
                    {name: "customer_ip", value: "`+data.customer_ip+`"}
                    {name: "referrer", value: "`+data.referrer+`"}
                    {name: "device", value: "`+data.device+`"}
                    {name: "utm_agency", value:"`+data.utm_agency+`"}
                    {name: "utm_source", value:"`+data.utm_source+`"}
                    {name: "utm_offer", value:"`+data.utm_offer+`"}
                    ]
                recipient:[
                    {name: "first_name", value: "`+data.recipient_first_name+`"}
                    {name: "last_name", value: "`+data.recipient_last_name+`"}
                    {name: "email", value: "`+data.recipient_email+`"}
                    {name: "phone", value: "`+data.recipient_phone+`"}
                ]
                cart: [
                    {
                    product_id: `+data.cart.product_id+`,
                    product:"`+data.cart.product+`",
                    price:`+data.cart.price*100+`,
                    qty:1,
                    type:"customer"
                    }
                    ]
                })
                {
                    approve_url,
                    cart_input
                }
                }`
    })
        .then((response) => {
            callback(response.data)
        })
        .catch((err) => {
            console.log(err);
        });
}

const giftCertificateCapturePaymentThroughPaypal = async (data, PayPalToken, PayPalPayerID, callback) => {
    let customerstring = JSON.stringify(data.customer)
    customerstring = customerstring.replaceAll('"name"', "name");
    customerstring = customerstring.replaceAll('"value"', "value");
    let recipientstring = JSON.stringify(data.recipient)
    recipientstring = recipientstring.replaceAll('"name"', "name");
    recipientstring = recipientstring.replaceAll('"value"', "value");
  //  let cartstring = JSON.stringify(data.cart)
  //  cartstring = cartstring.replaceAll('"name"', "name");
   // cartstring = cartstring.replaceAll('"value"', "value");
    console.log('giftCertificateCapturePaymentThroughPaypal', data)
    post('/', {
        query: `mutation {
            giftCertificateCapturePaymentThroughPaypal(input: {
                studio: "`+ data.studio +`"
                payerId: "`+ PayPalPayerID +`"
                token: "`+PayPalToken+`"
                stripeToken: ""
                notes: "`+data.notes+`"
                customer: `+customerstring+`
                recipient:`+recipientstring+`
                cart:[
                    {
                    product_id: `+data.cart[0].product_id+`,
                    product:"`+data.cart[0].product+`",
                    price:`+data.cart[0].price+`,
                    qty:1,
                    type:"customer"
                    }
                    ]
                coupon:[]
                })
                {
                    ckt_gift_certificate_id
                    studio_id
                    ckt_invoice_id
                    ckt_invoice_line_item_id
                    ckt_customer_id
                    invoice_date
                    first_name
                    last_name
                    mobile_phone
                    email
                    gift_certificate_id
                    gift_certificate_print
                    redeem_gift_certificate
                    last_processed_by
                    created_at
                    location {
                        ID
                        Name
                        url_slug
                    }
                    customer {
                      ckt_customer_id
                      first_name
                      last_name
                      email
                      mobile_phone
                      hear_about
                    }
                    lineItem {
                        product {
                            ckt_products_id
                            short_description
                        }
                    }
                    invoice {
                      ckt_invoice_id
                      studio_id
                      ckt_customer_id
                      ckt_coupon_id
                      invoice_status
                      refund_status
                      product_amt
                      product_cnt
                      coupon_amt
                      refund_amt
                      invoice_amt
                      notes
                      payment_type
                      transaction_id
                      approval_code
                      invoice_date
                      invoice_timestamp
                      refund_timestamp
                      order_id_old
                      checkout_url
                      utm_agency
                      utm_source
                      utm_offer
                      last_processed_by
                      created_at
                    }
                }
                }`
    })
        .then((response) => {
            callback(response.data)
        })
        .catch((err) => {
            console.log(err);
        });
}

const getIpAddress = ()=>{
    return axios.get('https://api.ipify.org?format=json')
}

const checkIsMobile = () => {
    let width = window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
    return width < 640
};

const generateGiftCertificate = (invoiceId) => {

    return post(process.env.VUE_APP_GIFT_CERTIFICATE_URL + '/api.php?invoice_id='+invoiceId, {}, {"Access-Control-Allow-Origin": "*"})
        .then((response) => {
            console.log('generateGiftCertificate', response)
            return response.data;
        })
        .catch((err) => {
            console.log('generateGiftCertificate', err);
        });
}

const sendEmailToCustomer = async (data, invoiceId, callback =null) => {
    console.log('paypalCheckoutHandler', data)
    post('/', {
        query: `mutation { 
    certificateEmailInvoice(input: {
         invoice_id: `+ invoiceId +` 
         certificate_path: "certificates/"
         customer: true 
         studio: true 
    }) 
    {
        ckt_invoice_id
        studio_id
        ckt_customer_id
        ckt_coupon_id
        invoice_status
        refund_status
        product_amt
        product_cnt
        coupon_amt
        refund_amt
        invoice_amt
        notes
        payment_type
        transaction_id
        approval_code
        invoice_date
        invoice_timestamp
        refund_timestamp
        order_id_old
        checkout_url
        utm_agency
        utm_source
        utm_offer
        last_processed_by
        created_at
      
}
}`
    })
        .then((response) => {
            if(callback == null)
                return response.data;
            else
                callback( response.data);
        })
        .catch((err) => {
            console.log(err);
        });
}

export {
    getLocationsById,
    fetchStudioSchedule,
    purchaseBooking,
    getLocationsByZipCode,
    stripeCheckoutHandler,
    paypalCheckoutHandler,
    getIpAddress,
    checkIsMobile,
    generateGiftCertificate,
    sendEmailToCustomer,
    giftCertificateCapturePaymentThroughPaypal
}