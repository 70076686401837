<script setup>

    import { useRoute, useRouter } from "vue-router";
    import { giftCertificateCapturePaymentThroughPaypal, sendEmailToCustomer} from "../mixins/api";

    const route = useRoute();
    const router = useRouter();
    var paypaltoken  =route.query.token;
  var paypalPayerId  =route.query.PayerID;

 var TempSessionData = JSON.parse(sessionStorage.getItem('ILKBChkSuccessTempData'));
  var TempCartdata= JSON.parse(TempSessionData.data.giftCertificatePaypalCheckout.cart_input);
  
const GFCPaymentpaypalSuccess = (data)=>{
  console.log('GFCPaymentpaypalSuccess', data)
  if(data.errors) {
    errorMessage.value = data.errors[0].message
    el.scrollIntoView();
  } else {
    sessionStorage.setItem('ILKBChkTempData',JSON.stringify(data));
    sessionStorage.setItem('ILKBChkSuccessTempData',JSON.stringify(data));
    sendEmailToCustomer({}, data.data.giftCertificateCapturePaymentThroughPaypal.ckt_invoice_id,RedirecttoThankyou).then((res)=>{
     })
}
}
const RedirecttoThankyou = async (data)=>{
  router.push({ path: "/thank-you" });
}
giftCertificateCapturePaymentThroughPaypal(TempCartdata, paypaltoken, paypalPayerId,  GFCPaymentpaypalSuccess)
</script>
<template>
  <div class="container" style="min-height: 100vh; display: flex; justify-content: center; align-items: center;">
    <div class="page">
      <div class="panel panel-default">
        <div class="panel-heading">
          <div class="loader-outer">
            <div class="loader"></div>
          </div>
          <p>Payment Is In Process</p>
        </div>
        <div class="panel-body">
          <p>Please wait. Your payment is under process...</p>
        </div>
      </div>
    </div>
  </div>
</template>
