import { createApp } from "vue";
import { createMetaManager } from 'vue-meta';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/scss/app.scss";
//import { StripePlugin } from '@vue-stripe/vue-stripe';

const options = {
  pk: process.env.VUE_APP_STRIPE_PUBLIC,
  testMode: process.env.VUE_APP_STRIPE_TEST,
  stripeAccount: process.env.STRIPE_ACCOUNT,
  apiVersion: process.env.API_VERSION,
  locale: process.env.LOCALE,
};

const app = createApp(App);

app.config.globalProperties.$filters = {
  activePage : 'ourmission',
  goto(elementId) {
    for (const item of document.querySelectorAll(".nav-link.active")) {
      item.classList.remove("active");
    }
    let currentNav = document.getElementById(elementId + "-link");
    currentNav.classList.add("active");
    document.getElementById(elementId).scrollIntoView({
      behavior: "smooth",
    });
  },
};

app.use(store).use(router).use(createMetaManager()).mount("#app");
