<script>
import { ref } from "vue";

export default {
    data() {
        return {
            customer: {
                firstname: "",
                lastname: "",
                email: "",
                phonenumber: "",
            },
            location: {
                shortname: "",
                line1: "",
                city: "",
                zipCode: "",
                phone: "",
            },
            invoice: {
                id: "",
                date: "",
                status: "",
                product_amt: "",
                product_cnt: "",
                invoice_amt: "",
            },
            giftCertificate: {
                id: "",
            },
            // Added property to store the entire data
            giftCertificateCheckoutData: null,
            // Added property for line items
            product: [],
            cerificateUrl:""
        };
    },
    mounted() {
        this.loadCustomerData();
    },
    methods: {
        loadCustomerData() {
            // Fetch data from session storage and set it to the 'customer' data property
            const giftCertificateCheckoutData = JSON.parse(sessionStorage.getItem('ILKBChkSuccessTempData'));
            // Declare variables for each relevant property
            let customerData = null;
            let locationData = null;
            let invoiceData = null;

            if (giftCertificateCheckoutData && giftCertificateCheckoutData.data && giftCertificateCheckoutData.data.giftCertificateCheckout) {
              var vardata = giftCertificateCheckoutData.data.giftCertificateCheckout;
                customerData = vardata.customer;
                locationData = vardata.location;
                invoiceData = vardata.invoice;
                this.product = [vardata.lineItem['product']];
                this.giftCertificate.id = vardata.gift_certificate_id;
                this.giftCertificateCheckoutData = giftCertificateCheckoutData;
              }
              if (giftCertificateCheckoutData && giftCertificateCheckoutData.data && giftCertificateCheckoutData.data.giftCertificateCapturePaymentThroughPaypal) {
                var vardata = giftCertificateCheckoutData.data.giftCertificateCapturePaymentThroughPaypal;
                customerData = vardata.customer;
                locationData = vardata.location;
                invoiceData = vardata.invoice;
                this.product = [vardata.lineItem['product']];
                this.giftCertificate.id = vardata.gift_certificate_id;
                this.giftCertificateCheckoutData = giftCertificateCheckoutData;
              }
              
                this.customer.firstname = customerData.first_name;
                this.customer.lastname = customerData.last_name;
                this.customer.email = customerData.email;
                this.customer.phonenumber = customerData.mobile_phone;

          
                this.location.shortname = locationData.Name;
                this.location.line1 = locationData.Line1;
                this.location.city = locationData.City;
                this.location.zipCode = locationData.ZipCode;
                this.location.phone = locationData.phone;

                
                this.invoice.id = invoiceData.ckt_invoice_id;
                this.invoice.date = invoiceData.invoice_date;
                this.invoice.status = invoiceData.invoice_status;
                this.invoice.product_amt = invoiceData.product_amt;
                this.invoice.product_cnt = invoiceData.product_cnt;
                this.invoice.invoice_amt = invoiceData.invoice_amt;

             
               
                let filename     =  this.customer.firstname + ' api.php' +this.customer.lastname+ ' ' + this.giftCertificate.id;
                filename  = filename.replace(/[^A-Za-z0-9 ]/g, ''); 
                filename = filename.replaceAll(' ', '_');
               // filename = "Tim_apiphpConlon_OSY62LK9PN.jpg";
                this.cerificateUrl =process.env.VUE_APP_GIFT_CERTIFICATE_IMG_URL+filename+'.jpg';
            
        },
    },
};
</script>
<style>
th, td {
  padding: 5px;
}
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
  .container {
    min-height: 67vh;
  }

  .card-header-custom {
    background-color: #007BFF;
    color: #FFFFFF;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .invoice-title {
    margin: 0;
  }

  .invoice-date {
    margin: 0;
  }

  .status {
    margin: 0;
  }

  .card-body-custom {
    background-color: #FFFFFF;
    color:black;
    padding: 10px;
  }

  .recipient-info {
    margin-top: 20px;
  }

  .total-table {
    margin-top: 10px;
  }
  .col-md-6{
    width:50%;
    display: inline-block;
  }
  .row{
    width:100%;
    display: block;
  }
  .col-lg-8{
    width:70%;
    display: inline-block;
  }
  .col-lg-4{
    width:30%;
    display: inline-block;
  }
</style>

<template>
  <div class="container mt-4">
    <div class="row">
      <div class="col-md-6 text-center">
        <a href="#" target="_blank" title="">
          <img :src =cerificateUrl  class="img-fluid" alt="Gift certificate">
        </a>
      </div>
      
      <div class="col-md-6">
        <div class="card">
          <div class="card-header-custom">
            <div>
              <h5 class="invoice-title">Invoice: {{ invoice.id }}</h5>
              <p class="invoice-date">{{ invoice.date }}</p>
            </div>
            <div>
              <p class="status">
                <strong>Status:</strong> {{ invoice.status }}
              </p>
            </div>
          </div>

          <div class="card-body card-body-custom">
            <div class="row">
              <div class="col-sm-6 mb-4">
                <h6 class="mb-3">From:
                    <strong>{{ location.shortname }}</strong>
                <span>{{ location.line1 }}</span>
                <span>{{ location.city }}, {{ location.zipCode }}</span>

                </h6>
               
              </div>
              <div class="col-sm-6 mb-4">
                <h6 class="mb-3">To:  <strong>{{ customer.firstname }} {{ customer.lastname }}</strong>
                <div>Email: {{ customer.email }}</div>
                <div>Phone: {{ customer.phonenumber }}</div></h6>
               
              </div>
            </div>
            <div class="table-responsive-sm">
              <table class="table table-striped">
                  <tr>
                    <th class="center">#</th>
                    <th>Item</th>
                    <th>Description</th>
                    <th class="right">Unit Cost</th>
                    <th class="center">Qty</th>
                    <th class="right">Total</th>
                  </tr>
                  <tr v-for="(prod, index) in product" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ prod.ckt_products_id }}</td>
                    <td>{{ prod.short_description }}</td>
                    <td class="right">{{ invoice.product_amt }}</td>
                    <td class="center">{{ invoice.product_cnt }}</td>
                    <td class="right">{{ invoice.invoice_amt }}</td>
                  </tr>
              </table>
            </div>
            <div class="row mt-4 recipient-info">
              <div class="col-lg-8 col-sm-7">
                <h6 class="mb-3">Recipient:    <strong>{{ customer.firstname }} {{ customer.lastname }}</strong></h6>
             
                <div>Email: {{ customer.email }}</div>
                <div>Phone: {{ customer.phonenumber }}</div>
                <h5>Certificate #: {{ giftCertificate.id }}</h5>
              </div>
              <div class="col-lg-4 col-sm-5">
                <table class="table table-clear total-table">
                  <tbody>
                    <tr>
                      <td class="left">
                        <strong>Subtotal</strong>
                      </td>
                      <td class="right">
                        <strong>{{ invoice.product_amt }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td class="left">
                        <strong>Total</strong>
                      </td>
                      <td class="right">
                        <strong>{{ invoice.product_amt }}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </div>

   
  </div>
</template>