import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home'
import Returnvu from '../views/Return'
import ThankYou from '../views/ThankYou';


window.location.href = process.env.VUE_APP_REDIRECT_TO_BYPASS;
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/return',
        name: 'return ',
        component: Returnvu,
    },
    {
        path: '/thank-you',
        name: 'ThankYou',
        component: ThankYou,
        props: {
            pageName: 'THANK_YOU_PAGE',
            showHeader: true,
            showFooter: true
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        } else {
            return {
                top: 0,
                behavior: 'smooth'
            }
        }
    }
})

export default router
